/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kdo jsme"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-il21l1 --style2 --full pb--60 pt--60" name={"hero"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/fbd898a4a94942aab06116746f7950af_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"O nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"popis"} border={""}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--left fs--20 ls--04 lh--16" style={{"maxWidth":1361}} content={"Milujeme jídlo a gastronomii. Catering vám připravíme na jakýkoliv event - firemní večírek, konference, kongresy, výroční akce či coffee breaky.<br><span style=\"color: rgb(0, 0, 0);\"><br>Většinu eventů pořádáme v unikátních prostorách historické budovy\nNárodního muzea. Tuto lokalitu jsme si oblíbili natolik, až jsme otevřeli\nBistro Národní muzeum a kavárnu Oliver's Coffee Cup jen pár kroků od\ntéto nádherné budovy.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--50 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--center">
              
              <Image className="--center" src={"https://cdn.swbpg.com/t/18947/3c33545c8a354238a50d0e6ff2306f8a_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":462}} srcSet={"https://cdn.swbpg.com/t/18947/3c33545c8a354238a50d0e6ff2306f8a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/3c33545c8a354238a50d0e6ff2306f8a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/3c33545c8a354238a50d0e6ff2306f8a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/3c33545c8a354238a50d0e6ff2306f8a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/3c33545c8a354238a50d0e6ff2306f8a_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--center" src={"https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=860x_.JPG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":598}} srcSet={"https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=350x_.JPG 350w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=660x_.JPG 660w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=860x_.JPG 860w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/18947/dee90099296a4b18b84bafe116953a48_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--center" src={"https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":504}} srcSet={"https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/6127839a44cb485b9d96494c7585ec52_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Specializujeme se na"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image className="--center" src={"https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/df37a0a1460a4a7582444551de72f6b6_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center mt--0" content={"KONFERENCE"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/71c9b5c88e8f47d6a9e8a622b8fdcdaa_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/18947/71c9b5c88e8f47d6a9e8a622b8fdcdaa_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/71c9b5c88e8f47d6a9e8a622b8fdcdaa_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/71c9b5c88e8f47d6a9e8a622b8fdcdaa_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center mt--0" content={"VÝROČNÍ AKCE<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/219e736ed3f04956855f11469453fe7e_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/18947/219e736ed3f04956855f11469453fe7e_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/219e736ed3f04956855f11469453fe7e_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/219e736ed3f04956855f11469453fe7e_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center mt--0" content={"EVENTY"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/959a206864514cabb63afc9af516d176_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/18947/959a206864514cabb63afc9af516d176_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/959a206864514cabb63afc9af516d176_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/959a206864514cabb63afc9af516d176_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center mt--0" content={"COFFEE BREAKY"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/24b2b40af3df4bca8122de5318138741_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center mt--0" content={"FIREMNÍ VEČÍRKY"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":60}} srcSet={"https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/16d652c6f6354c27bd92b9ddefd64822_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center mt--0" content={"OSLAVY"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border2 pb--60 pt--60" name={"prostor"} style={{"backgroundColor":"rgba(224, 224, 224, 1)"}} border={"2"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Event na výjimečné adrese"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">HISTORICKÁ BUDOVA NÁRODNÍHO MUZEA</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Uspořádejte svůj event v unikátních prostorách\nhistorické budovy Národního muzea.\n<br><br>Pronajměte si salońek, panteon, západní\ndvoranu nebo kupoli.\n<br><br>Pro společenské akce jsou k dispozici prostory\nPanteonu, schodišťová hala, západní či\nvýchodní zastřešené dvorany a pro akce s\nmenší kapacitou prostory kupole s jedinečným\nvýhledem na celou Prahu.</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Kapacita</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Kupole - 100 (raut)\n<br>Pantheon - 200 (raut) <br>Západní dvorana - 600 a více (raut) / 540 (gala)</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image className="mt--30" src={"https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f918666d758b49b3a0f156c3cce7b241_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 mt--50" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box mt--30 pt--60" content={"<span style=\"font-weight: bold;\">NOVÁ BUDOVA NÁRODNÍHO MUZEA</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">Budova s atraktivním umístěním přímo vedle Historické budovy Národního muzea nabízí prostory pro konání galavečeří, konferencí, rautů či přednášek.<br><br>V budově lze využít velký kongresový sál se stupňovitě uspořádanými sedadly, společenský sál, přednáškovou místnost, venkovní atrium se zimní zahradou či zasedací sál v pátém patře s exkluzivním výhledem.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/18947/7909e49b2fa843e0a8079f045fb564bf_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/18947/7909e49b2fa843e0a8079f045fb564bf_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/7909e49b2fa843e0a8079f045fb564bf_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/7909e49b2fa843e0a8079f045fb564bf_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wb130hm1nm"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn3 btn-box--shape2 btn-box--cColor1 mb--30" innerClassName="" use={"page"} href={"/kontakt"} content={"CHCI USPOŘÁDAT AKCI"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--4 flex--center" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":215}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":123}} srcSet={"https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/a0ef953d5c73431e80aa9839c213ae08_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" style={{"maxWidth":269}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">E-mail<br></span><br>info@olivacatering.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"font-weight: bold;\">Telefon<br></span><br>+420 723 101 012</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center" content={"<span style=\"font-weight: 700; color: rgb(255, 255, 255);\">Sledujte nás</span><br>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<a href=\"https://www.instagram.com/olivacatering_cz/\" style=\"color: rgb(255, 255, 255);\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}